import Flickity from 'flickity';
import 'flickity-fade';
import 'flickity-imagesloaded';

const TestimonialSlider = (parentNode) => {
  parentNode.classList.add('opacity-0');
  console.log(Flickity);

  const firstChild = parentNode.querySelector('.gp-testimonial-slider').children.item(0);
  if (firstChild) {
    parentNode.style.setProperty('--testimonial-temp-height', firstChild.offsetHeight + 'px');
  }

  setTimeout(_ => {
    let flkty = new Flickity( parentNode.querySelector('.gp-testimonial-slider'), {
      wrapAround: true,
      draggable: false,
      loop: true,
      autoPlay: false,
      pageDots: false,
      prevNextButtons: false,
      fade: true,
      adaptiveHeight: true,
      imagesLoaded: true,
      on: {
        ready: _ => {
          parentNode.classList.remove('opacity-0');
        }
      }
    });

    parentNode.querySelector('.slider-next').addEventListener('click', e => {
      e.preventDefault();
      flkty.next();
    });
    parentNode.querySelector('.slider-prev').addEventListener('click', e => {
      e.preventDefault();
      flkty.previous();
    });
  }, 100);

};

export default TestimonialSlider;