import Flickity from 'flickity';

const LogoSlider = sliderNode => {
  console.log(Flickity);
  const slides = sliderNode.querySelectorAll('.logo-slide');

  const options = {
    accessibility: true,
    prevNextButtons: false,
    pageDots: false,
    preload: 3,
    imagesLoaded: true,
    wrapAround: true,
    autoPlay: 4000,
    selectedAttraction: 0.01,
    friction: 0.15,
    groupCells: 2,
    cellAlign: 'left',
  };

  let flkty = new Flickity(sliderNode, options);
}

export default LogoSlider;