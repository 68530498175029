import Flickity from 'flickity';

const TeamCarousel = section => {
  if (document.querySelector('.team-carousel')) {
      console.log(Flickity);

      var options = {
        accessibility: true,
        prevNextButtons: false,
        pageDots: false,
        preload: 3,
        imagesLoaded: true,
        wrapAround: true,
        autoPlay: 3000,
        selectedAttraction: 0.01,
        friction: 0.15,
        contain: true,
        cellAlign: 'center',
      };

      if ( matchMedia('screen and (max-width: 768px)').matches ) {
        options.cellAlign = 'left';
      } else {
        options.groupCells = 2;
      }

      var teamCarousel = document.querySelectorAll('[data-teamcarousel]');

      teamCarousel.forEach((teamCarousel) => new Flickity(teamCarousel, options));
  }
}

export default TeamCarousel;