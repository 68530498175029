import { inView, animate } from "motion";
import Navigation from "./Blocks/Header/navigation";
import simpleParallax from 'simple-parallax-js';

import TeamCarousel from "./Blocks/TeamCarousel/teamCarousel.js";
import LogoSlider from "./Blocks/LogoSlider/logoSlider.js";
import TestimonialSlider from "./Blocks/TestimonialSlider/testimonialSlider.js";
import Flickity from 'flickity';

const domReady = () => {
  Navigation();
  initModules();
  initScrollAnimations();

  // Button smooth scroll if hash found
  const buttonLinkNodes = document.querySelectorAll('.block-cyan-btn');
  if (buttonLinkNodes.length) {
    buttonLinkNodes.forEach(button => {
      button.addEventListener('click', e => {
        if (button.hash) {
          const targetEl = document.querySelector(button.hash);

          if (targetEl) {
            e.preventDefault();

            targetEl.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
          }
        }
      })
    });
  }
};

function initModules() {
  const teamCarousel = document.querySelectorAll('.team-carousel');
  if (teamCarousel.length) {
    TeamCarousel();
  }

  const logoSliderNodes = document.querySelectorAll('[data-logoslider]');
  if (logoSliderNodes.length) {
    logoSliderNodes.forEach(slider => {
      LogoSlider(slider);
    });
  }

  const testimonialSliderNodes = document.querySelectorAll(".giantpeach-testimonialslider");
  if (testimonialSliderNodes.length) {
    testimonialSliderNodes.forEach(slider => {
      TestimonialSlider(slider);
    });
  }

}

function initScrollAnimations() {
  let transitionableBlocks = document.querySelectorAll(".transition-block");
  transitionableBlocks.forEach(el => {
    el.style.opacity = 0;
    el.style.transform = 'translateY(60px)';
  });

  let inViewStop = inView(transitionableBlocks, ({ target }) => {
    animate(
      target,
      { opacity: 1, transform: "none" },
      { delay: target.dataset.delay ? target.dataset.delay : 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
    );

    return () => {
      animate(
        target,
        { opacity: 0, transform: "translateY(60px)" },
        { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );
    };
  });

  document.addEventListener("block:load", () => {
    transitionableBlocks = document.querySelectorAll(".transition-block");
    inViewStop(); // Override previous listeners
    inViewStop = inView(transitionableBlocks, ({ target }) => {
      animate(
        target,
        { opacity: 1, transform: "none" },
        { delay: 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );

      return () => {
        animate(
          target,
          { opacity: 0, transform: "translateY(60px)" },
          { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
        );
      };
    });
  });


  // fix btn transition / wrap with div
  const cardContentBtnElements = document.querySelectorAll('.giantpeach-card-content > .block-cyan-btn, .giantpeach-cardinner-content > .block-cyan-btn');
  cardContentBtnElements.forEach(el => {
    const tempDiv = document.createElement('div');
    tempDiv.classList.add('block-cyan-btn-wrapper');
    el.after(tempDiv);
    el.classList.add('w-fit');
    tempDiv.appendChild(el);
  });
  // fix end

  const cardContentElements = document.querySelectorAll(`
      .giantpeach-card-content > .wp-block-heading, 
      .giantpeach-card-content > p, 
      .giantpeach-card-content > .block-cyan-btn-wrapper, 
      .giantpeach-card-content > .wp-block-list, 
      .giantpeach-cardinner-content > .wp-block-heading, 
      .giantpeach-cardinner-content > p, 
      .giantpeach-cardinner-content > .wp-block-list, 
      .giantpeach-cardinner-content > .block-cyan-btn-wrapper
    `);
  cardContentElements.forEach(el => {
    el.dataset.fadeto = getComputedStyle(el)['opacity'];
    el.style.opacity = 0;
    el.style.transform = 'translateY(60px)';
  });

  inView(cardContentElements, ({ target }) => {
    animate(
      target,
      { opacity: target.dataset.fadeto ? target.dataset.fadeto : 1, transform: "none" },
      { delay: 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
    );

    return () => {
      animate(
        target,
        { opacity: 0, transform: "translateY(60px)" },
        { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );
    };
  });

  // Parallax
  const parallaxNodes = document.querySelectorAll(".parallax-wrapper");
  if (parallaxNodes.length) {
    parallaxNodes.forEach(el => {

      let options = {
        overflow: true,
        scale: el.dataset?.scale ?? 1.2,
        orientation: el.dataset?.orientation ?? 'up'
      };

      new simpleParallax(el, options);
    })
  }
}

document.addEventListener("DOMContentLoaded", domReady);